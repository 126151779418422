"use strict";

$(document).ready(function () {
  var title = $(".crts__card-title");
  var arrow = $(".carousel-control-next");
  var image = $(".crts__image");
  var carouselCol = $("#carouselCol"); // let sendFile = false;

  arrow.css("left", function () {
    return image.outerWidth();
  });

  if (window.matchMedia("(min-width: 768px)").matches) {
    carouselCol.css("min-height", function () {
      return carouselCol.height();
    });
    arrow.css("top", function () {
      return title.position().top + title.outerHeight() / 2;
    });
  } //else {
  //     arrow.css("top", () => image.position().top + image.outerHeight() / 2);
  // }


  $(window).resize(function () {
    arrow.css("left", function () {
      return image.outerWidth();
    });
    carouselCol.css("min-height", function () {
      return carouselCol.height();
    });

    if (window.matchMedia("(min-width: 768px)").matches) {
      arrow.css("top", function () {
        return title.position().top + title.outerHeight() / 2;
      });
    } // else {
    //     arrow.css("top", () => image.position().top + image.outerHeight() / 2);
    // }

  });
  $('.carousel').on('slide.bs.carousel', function (e) {
    $(".doubleInd li").removeClass("active");
    $(".doubleInd").find("[data-slide-to='" + e.to + "']").addClass("active");
  }); // this is the id of the form
  // $("form").submit(function (e) {
  //     e.preventDefault(); // avoid to execute the actual submit of the form.
  //     if (sendFile) {
  //         downloadFile("/download/leaflet-mobile-substations.pdf", "leaflet - mobile - substations")
  //     } else {
  //         var form = $(this);
  //         var url = form.attr('action');
  //         $.ajax({
  //             type: "POST",
  //             url: url,
  //             data: form.serialize(), // serializes the form's elements.
  //             success: function (res) {
  //                 if (res.status == 1) {
  //                     sendFile = true;
  //                     $('#respond').html('<div class="col-12 mb-4"><p class="border-0 text-center form-control bg-transparent"><b>Thanks for your interest</b></p></div>');
  //                     downloadFile("/download/leaflet-mobile-substations.pdf", "leaflet - mobile - substations")
  //                 } else {
  //                     $('#respond').after('<div class="form-row"><div class="col-12 mb-4"><p class="border-0 text-center form-control bg-transparent text-danger"><b>Error' + res.error + '</b></p></div></div>');
  //                 }
  //             }
  //         });
  //     }
  // });
}); // function downloadFile(data, fileName, type = "application/pdf") {
//     const a = document.createElement("a");
//     a.style.display = "none";
//     document.body.appendChild(a);
//     a.href = data;
//     a.setAttribute("download", fileName);
//     a.click();
//     document.body.removeChild(a);
// }